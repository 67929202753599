import {useNavigate} from "react-router-dom";
import moment from 'moment'
import {BlurhashImage} from "../blurhash/BlurhashImage";
import {getEventPlace} from "../../util/event-place";
import {useDispatch, useSelector} from "react-redux";
import {setProgramCategoryId} from "../../redux/actions/setProgramCategoryId";


const ProgramCard = (props) => {

    moment.updateLocale('hu', {
        months: [
            "Január", "Február", "Március", "Április", "Május", "Június", "Július",
            "Augusztus", "Szeptember", "Október", "November", "December"
        ]
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {eventPlace} = useSelector(state => state);

    return (
        <div className="program-card-div" onClick={() => {
            navigate('/programok/programdetailpage/' + props.msg.id, {
                state: {
                    programId: props.msg.id,
                    program: props.msg,
                    categoryName: props.categoryName,
                    linkText: props.linkText
                }
            });
            if (props.categoryName !== null) {
                dispatch(setProgramCategoryId(null))
            }
        }}>
            <div className="image-div">
                {props.kovetkezoprogramok && getEventPlace(eventPlace.id) !== "galeria" && (
                    props.msg.eventTypes.filter(k => k.name === "Ingyenes programok").length === 0 ? <div onClick={() => {
                        if (props.msg.ticketPrice > 0 && props.msg.ticketPrice !== null && props.msg.ticketPrice !== "" && props.msg.ticketPurchaseLink !== null && props.msg.ticketPurchaseLink !== "") {
                            window.open(props.msg.ticketPurchaseLink, '_blank')
                        }
                    }}>
                        {eventPlace && (
                            <img
                                src={props.msg.eventTypes.filter(k => k.name === "Ingyenes programok").length > 0 ? require("../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_ingyenes_ikon.svg") : require("../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_program_ikon.svg")}
                                className="program-ikon-image" alt=""
                                style={{opacity: props.msg.ticketPurchaseLink !== null ? "1" : "0.5"}}/>
                        )}
                    </div> : null
                )}
                <div className="program-image">
                    <BlurhashImage image={props.msg.listImage} blurhash={props.msg.listImageBlurhash}/>
                </div>
                <div className="overlay" style={{translate: `0 -100%`}}/>
            </div>
            <div className="datum-div">
                {props.msg.dateFromText ?
                    <span>{props.msg.dateFromText}</span> :
                    <>
                        {props.msg.all_day === true ?
                            <span>{moment.unix(props.msg.dateFrom).locale('hu').format('MMMM DD.')} egész nap</span>
                            :
                            <span>{moment.unix(props.msg.dateFrom).locale('hu').format('MMMM DD., HH:mm')}</span>
                        }
                    </>
                }
            </div>
            <div className="text-div">
                <p>{props.msg.title}</p>
                <span>{props.msg.eventPlace?.name}</span>
            </div>
        </div>
    );
};

export default ProgramCard;
