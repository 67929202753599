import {getReplaceText} from "./get-replace-text";

export const eventPlaces = [
    {
        title: "A Vértes Agorája",
        eventplace: "ava",
        kiemelt: "#F0B323",
        font: 'Urbanist',
        id: 1,
        analytics: 'G-9RZNF15Q5D'
    },
    {
        title: "József Attila Művelődési Ház",
        eventplace: "jamh",
        kiemelt: "#54A8DE",
        font: 'Urbanist',
        id: 2,
        analytics: ''
    },
    {
        title: "Kertvárosi Bányász Művelődési Otthon",
        eventplace: "kbmo",
        kiemelt: "#FA8934",
        font: 'Urbanist',
        id: 3,
        analytics: ''
    },
    {
        title: "Puskin Művelődési Ház",
        eventplace: "pmh",
        kiemelt: "#AFCA21",
        font: 'Urbanist',
        id: 4,
        analytics: 'G-QWH77MN2YT'
    },
    {
        title: "Széchenyi István Művelődési Ház",
        eventplace: "szimh",
        kiemelt: "#9A96F7",
        font: 'Urbanist',
        id: 5,
        analytics: 'G-HG1GWJXB7R'
    },
    {
        title: "Kortárs Galéria",
        eventplace: "galeria",
        kiemelt: "#F0B323",
        font: 'IM FELL English',
        id: 6,
        analytics: 'G-G1LX2R7D51'
    }
];

export const getEventPlace = (id) => {
    return eventPlaces.find((eventPlace) => eventPlace.id === id).eventplace;
}

export const getEventPlaceAnalytics = (id) => {
    return eventPlaces.find((eventPlace) => eventPlace.id === id).analytics;
}

export const getCurrentEventPlaceId = () => {
    const url = new URL(window.location.href);
    const subdomain = url.hostname.substring(0, url.hostname.length - process.env.REACT_APP_DOMAIN.length - 1);
    if (subdomain.length === 0) {
        return 1;
    }
    return eventPlaces.find((place) => place.eventplace === subdomain)?.id;
}

