import React, {useEffect, useState} from "react";
import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import moment from 'moment'
import ProgramCard from "../components/cards/ProgramCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import back from "../assets/media/icons/back.svg"
import { MDBContainer, MDBCol, MDBRow, MDBInput } from 'mdb-react-ui-kit';
import {Calendar} from "../components/calendar/calendar";
import "react-calendar/dist/Calendar.css";
import {useDispatch, useSelector} from "react-redux";
import {getEventPlace} from "../util/event-place";
import {listArchiveEvents} from "../action/listArchiveEvents";
import {pushArchiveEvents} from "../redux/actions/pushArchiveEvents";

const ProgramokListPage = () => {

    moment.updateLocale('hu', {
        months: [
            "Január", "Február", "Március", "Április", "Május", "Június", "Július",
            "Augusztus", "Szeptember", "Október", "November", "December"
        ]
    });

    const loaderData = useLoaderData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // STATES
    const [isActuelleProgram, setIsActuelleProgram] = useState(false);
    const [isSearchFunction, setIsSearchFunction] = useState(false);
    const [actuelleDate, setActuelleDate] = useState();
    const [searchText, setSearchText] = useState("")
    const [direction, setDirection] = useState(window.outerWidth);
    const [showMonthSelect, setShowMonthSelect] = useState(false);
    
    const { archiveEvents, areMoreArchiveEvents, guestEvents, eventPlace } = useSelector(state => state)

    const eventList = getEventPlace(eventPlace.id) !== "galeria" ? (location.state?.dateFrom !== undefined ? guestEvents.filter(k => k.dateFrom >= Math.round(location.state?.dateFrom.getTime() / 1000)) : guestEvents) : archiveEvents;

    const filterEvents = (events, m) => {
        let recurringIds = [];
        events = events.filter( datum => moment.unix(datum.dateFrom).locale('hu').format('MMMM') === m );
        return getEventPlace(eventPlace.id) !== "galeria" ? events?.filter(e => {
            if (e.recurrenceId === null) {
                return true;
            }
            if (recurringIds.includes(e.recurrenceId)) {
                return false;
            }
            recurringIds.push(e.recurrenceId);
            return true;
        }) : events;
    }

    const set = new Set(eventList.map(k => {
        return (moment.unix(k.dateFrom).locale('hu').format('MMMM'))
    }))
    const monthList = Array.from(set);

    useEffect(() => {
        setIsActuelleProgram(false);
        setActuelleDate(moment());

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [
        loaderData.loadedAt
    ])

    const loadMorePrograms = async () => {

        dispatch(pushArchiveEvents({ archiveEvents: await listArchiveEvents({ to: eventList[eventList.length - 1].dateTo, limit: 9 }) }));

    };

    const handleWindowSizeChange = () => {
        setDirection(window.visualViewport.width);
    };

    useEffect(() => {
        window.visualViewport.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const mark = eventList?.filter(datum => moment(Date(datum.dateFrom)).format('MM') === moment().format('MM')).map(k => {
        return (moment.unix(k.dateFrom)).format("DD-MM-YYYY")
    })

    const closeMonthSelect = (e) => {
        if (e.target.className === 'month-select') {
            return;
        }
        setShowMonthSelect(false);
    }

    return (
        <>
            <section className="kovetkezo-programok-section events-page" id="kovetkezo-programo-section" onClick={closeMonthSelect}>
                <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>

                  {getEventPlace(eventPlace.id) !== "galeria" && (
                      <div className="datum-search-div">
                        {!isSearchFunction ? <>
                              {mark && (<>
                                  <Calendar selectedDate={ actuelleDate } onDateSelect={ date => { setActuelleDate(date); setIsActuelleProgram(true); } } activeDates={ mark } onMonthSelect={() => { setShowMonthSelect(false); setIsActuelleProgram(false);
                                      setActuelleDate(moment()) } } onMonthSelectOpen={() => setShowMonthSelect(true)} showMonthSelect={showMonthSelect} />
                                        <button className="datum-search-button detail" type="button" onClick={() => {
                                            setIsSearchFunction(true);
                                            setIsActuelleProgram(false);
                                            setActuelleDate(moment())
                                        }}>{eventPlace !== undefined && (<img
                                            src={require("../assets/media/icons/" + getEventPlace(eventPlace.id) + "_search_ikon.svg")}
                                            alt="search ikon"/>)}</button>
                                    </>)}

                                </> :
                                <>
                                    <MDBInput value={searchText}
                                              name='searchtext'
                                              type="text"
                                              onChange={(e) => setSearchText(e.target.value)}
                                              label='Program keresése'/>
                                    <button className="datum-search-button detail" type="button"
                                            onClick={() => setIsSearchFunction(false)}>{eventPlace && (
                                        <img
                                            src={require("../assets/media/icons/" + getEventPlace(eventPlace.id) + "_calendar.svg")}
                                            alt="search ikon"/>)}</button>
                                </>}
                        </div>
                    )}
                    <div className="navigate-div" style={{ marginTop: getEventPlace(eventPlace.id) === "galeria" ? 0 : undefined }}>
                        <button onClick={() => {
                            navigate('/home')
                        }}>Főoldal
                        </button>
                        <img src={tovabb} alt="tovabb"/>
                        <button onClick={() => { setIsActuelleProgram(false);
                            setActuelleDate(moment()); setSearchText("") }}>{getEventPlace(eventPlace.id) === "galeria" ? "Korábbi kiállítások" : "Következő programok"}</button>
                    </div>
                    <div>
                        {getEventPlace(eventPlace.id) === "galeria" && (
                            <div className="program-title">
                                <span>Korábbi kiállítások</span>
                            </div>
                        )}
                        {getEventPlace(eventPlace.id) !== "galeria" && (<>
                            {!isActuelleProgram ? <>
                                    {monthList.map((l, m) => {
                                        return (
                                            <>
                                                {filterEvents(eventList, l)?.filter(k => k.title.toLowerCase().match(searchText.toLowerCase())).length > 0 && (
                                                    <div key={m} className="programok-list-section">
                                                        <div className="program-title">
                                                            <span>{l + "i programok"}</span>
                                                        </div>
                                                        <MDBRow className="programok-row">
                                                            {filterEvents(eventList, l).length > 0 && (filterEvents(eventList, l)?.filter(k => k.title.toLowerCase().match(searchText.toLowerCase())).map((k, n) => {
                                                                return (
                                                                    <MDBCol xl="4" lg="6" md="6" size="12"
                                                                            className="programok-col px-0" key={n}>
                                                                        <ProgramCard msg={k} kovetkezoprogramok={true}
                                                                                     categoryName={"Következő programok"}
                                                                                     linkText={"kovetkezoprogramok"}/>
                                                                    </MDBCol>
                                                                )
                                                            }))}

                                                        </MDBRow>

                                                    </div>
                                                )}
                                            </>
                                        )
                                    })}
                                    {searchText !== "" && (<>
                                        {eventList?.filter(k => k.title.toLowerCase().match(searchText.toLowerCase())).length > 0 ?
                                            <MDBRow>
                                                <MDBCol md="4" className="loadmore-div-2">
                                                    <button type="button" className="muster-button kiemelt" onClick={() => {
                                                        setSearchText("")
                                                    }}><img src={back} alt="back"/><span> Vissza</span></button>
                                                </MDBCol>
                                            </MDBRow> :
                                            <MDBRow className="mx-0" style={{marginTop: "72px"}}>
                                                <MDBCol md="4" className="px-0 pt-5">
                                                    Nincs találat
                                                </MDBCol>
                                            </MDBRow>
                                        }
                                    </>)}
                                </> :
                                <>
                                    <div className="program-title program-title--search">
                                        <span>{"Keresés: Programok-" + moment(actuelleDate).locale('hu').format('MMMM DD')}</span>
                                    </div>
                                    <MDBRow className="programok-row">
                                        {eventList?.filter(k => moment.unix(k.dateFrom).locale('hu').format('YYYY-MM-DD') === moment(actuelleDate).locale('hu').format('YYYY-MM-DD')).map((k, n) => {
                                            return (
                                                <MDBCol md="4" className="programok-col" key={n}>
                                                    <ProgramCard msg={k} kovetkezoprogramok={true}/>
                                                </MDBCol>
                                            )
                                        })}
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="4" className="loadmore-div-2">
                                            <button type="button" className="muster-button kiemelt" onClick={() => {
                                                setIsActuelleProgram(false);
                                                setActuelleDate(moment())
                                            }}><img src={back} alt="back"/><span> Vissza</span></button>
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            }

                        </>)}

                        {getEventPlace(eventPlace.id) === "galeria" && (<>
                            <MDBRow className="programok-row">
                                {eventList?.filter(k => parseInt(k.dateTo) < Math.round(Date.now() / 1000)).map((k, n) => {
                                    return (
                                        <MDBCol md="4" className="programok-col" key={n}>
                                            <ProgramCard msg={k} kovetkezoprogramok={false}/>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                            {areMoreArchiveEvents && (
                                <div className="loadmore-div">
                                    <button type="button" className="muster-button kiemelt-next"
                                            onClick={loadMorePrograms}><span>Továbbiak </span></button>
                                </div>)}
                        </>)}
                    </div>


                </MDBContainer>
            </section>
        </>
    );
};

export default ProgramokListPage;
